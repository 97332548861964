import React, { useEffect, useState } from 'react';

import C08V3PeopleOrg from './../../../Components/Organisms/Personas/C08/v3'

import {URL_WEB_SERVICE_LOCAL_STORAGE} from '../../../constants/general'
import { PlanService, ChannelsService, CategoriesService, FranjaService,} from '../../../actions/HttpCalls'
import { HttpClient } from '../../../actions/HttpClient'

const httpClient = new HttpClient();
const categoriesService = new CategoriesService(httpClient);
const planService = new PlanService(httpClient);
const franjaService = new FranjaService(httpClient);
const channelsService = new ChannelsService(httpClient);


function HomePeopleContainerUi(props) {
    const [dataPlanesBk, setDataPlanesBk] = useState([])
    const [dataChannelsBk, setDataChannelsBk] = useState([])
    const [dataFranjasBk, setDataFranjasBk] = useState([])

    const getValuesDefault = async () => {
        if( URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1 ){

            const categories = await categoriesService.getCategoriesItems()
            // setDataCategorias(categories)
            
            categories.forEach(async (c, index)=>{
                const franjas = await franjaService.getFranjaItems(c.fi_categoria)
                if(franjas.length){
                    setDataFranjasBk(dataFranjasBk => [...dataFranjasBk, franjas])
                }
            })
            
            const planes = await planService.getPlanesItems()
            setDataPlanesBk(planes)

            const channels = await channelsService.getChannelsItems()
            setDataChannelsBk(channels)
            
            console.log("planes", planes)
            console.log("channels", channels)
        }
	}

    useEffect(() => {
        getValuesDefault()
		// eslint-disable-next-line
	}, [])
    return (
        <>
        {
            URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1  ?
            <>
                {
                    dataChannelsBk.length > 0 ?
                    <C08V3PeopleOrg
                        dataChannelsBk={dataChannelsBk}
                        dataPlanesBk={dataPlanesBk}
                        dataFranjasBk={dataFranjasBk}
                    />
                    :null
                }
            </>
            :
            <C08V3PeopleOrg
                dataChannelsBk={dataChannelsBk}
                dataPlanesBk={dataPlanesBk}
                dataFranjasBk={dataFranjasBk}
            />

        }
        </>
    )
}

export default HomePeopleContainerUi
